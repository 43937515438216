import { graphql, useStaticQuery } from "gatsby"
import keywordExtractor from "keyword-extractor"
import React, { useContext } from "react"
import { Helmet } from "react-helmet"

import OgImage from "../media/images/FM-og-image.png"
import { LanguageContext } from "./i18n"

type Props = {
  desc?: string
  title?: string
}

type QueryResult = {
  site: {
    siteMetadata: {
      author: string
      title: string
      url: string
    }
  }
  wp: {
    generalSettings: {
      description: string
      title: string
    }
  }
}

export default function Seo({ desc, title }: Props) {
  const {
    site: {
      siteMetadata: { author, title: mainTitle, url },
    },
    wp: {
      generalSettings: {
        description: wordpressDescription,
        title: wordpressName,
      },
    },
  } = useStaticQuery<QueryResult>(
    graphql`
      query {
        site {
          siteMetadata {
            author
            title
            url
          }
        }
        wp {
          generalSettings {
            description
            title
          }
        }
      }
    `
  )
  const { language } = useContext(LanguageContext)

  const seoTitle = title ? `${title} — ${mainTitle}` : mainTitle
  const seoDescription = desc ? desc : wordpressDescription

  return (
    <Helmet htmlAttributes={{ lang: language }}>
      <meta charSet="utf-8" />
      <meta
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        name="viewport"
      />
      <title>{seoTitle}</title>
      <meta content={seoDescription} name="description" />
      <meta content={seoDescription} property="og:description" />
      <meta content={seoTitle} property="og:title" />
      <meta content="website" property="og:type" />
      <meta content={url} property="og:url" />
      <meta content={OgImage} property="og:image" />
      <meta content={wordpressName} property="og:site_name" />
      <meta content="summary" name="twitter:card" />
      <meta content={author} name="twitter:creator" />
      <meta content={title} name="twitter:title" />
      <meta content={seoDescription} name="twitter:description" />
      <meta content="index,follow" name="robots" />
      <meta
        content={keywordExtractor
          // eslint-disable-next-line camelcase
          .extract(seoDescription, { remove_duplicates: true })
          .join(",")}
        name="keywords"
      />
    </Helmet>
  )
}
