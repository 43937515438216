import React from "react"

type Props = {
  children: React.ReactNode
  className?: string | undefined
  href?: string
}

export default function RemoteLink({ children, className, href }: Props) {
  if (!href) {
    return null
  }

  return (
    <a
      className={className}
      href={href}
      rel="noreferrer noopener nofollow"
      target="_blank"
    >
      {children}
    </a>
  )
}
