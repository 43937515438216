import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import AnnouncementIcon from "@material-ui/icons/Announcement"
import ContactSupportIcon from "@material-ui/icons/ContactSupport"
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun"
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople"
import EventIcon from "@material-ui/icons/Event"
import GroupIcon from "@material-ui/icons/Group"
import HomeIcon from "@material-ui/icons/Home"
import InfoIcon from "@material-ui/icons/Info"
import StoreIcon from "@material-ui/icons/Store"
import React, { useContext } from "react"

import useCommonStyles from "../../library/useCommonStyles"
import { LanguageContext, Link } from "../i18n"
import RemoteLink from "../RemoteLink"

export default function MobileMenu() {
  const { language } = useContext(LanguageContext)
  const commonClasses = useCommonStyles()

  return (
    <List style={{ minWidth: "50vw" }}>
      <Link
        className={`${commonClasses.link} ${commonClasses.colorBlack}`}
        to="/"
      >
        <ListItem button divider>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            primary={language === "pl" ? "STRONA GŁÓWNA" : "HOMEPAGE"}
          />
        </ListItem>
      </Link>
      <Link
        className={`${commonClasses.link} ${commonClasses.colorBlack}`}
        to="/timetable"
      >
        <ListItem button>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary={language === "pl" ? "REZERWUJ" : "BOOK"} />
        </ListItem>
      </Link>
      <Link
        className={`${commonClasses.link} ${commonClasses.colorPrimary}`}
        to="/membership"
      >
        <ListItem button>
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary={language === "pl" ? "KUPUJ" : "BUY"} />
        </ListItem>
      </Link>
      <Link
        className={`${commonClasses.link} ${commonClasses.colorBlack}`}
        to="/about-us"
      >
        <ListItem button>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary={language === "pl" ? "O NAS" : "ABOUT US"} />
        </ListItem>
      </Link>
      <Link
        className={`${commonClasses.link} ${commonClasses.colorBlack}`}
        to="/team"
      >
        <ListItem button>
          <ListItemIcon>
            <EmojiPeopleIcon />
          </ListItemIcon>
          <ListItemText primary={language === "pl" ? "ZESPÓŁ" : "TEAM"} />
        </ListItem>
      </Link>
      <Link
        className={`${commonClasses.link} ${commonClasses.colorBlack}`}
        to="/classes"
      >
        <ListItem button>
          <ListItemIcon>
            <DirectionsRunIcon />
          </ListItemIcon>
          <ListItemText primary={language === "pl" ? "ZAJĘCIA" : "CLASSES"} />
        </ListItem>
      </Link>
      <Link
        className={`${commonClasses.link} ${commonClasses.colorBlack}`}
        to="/news"
      >
        <ListItem button>
          <ListItemIcon>
            <AnnouncementIcon />
          </ListItemIcon>
          <ListItemText primary={language === "pl" ? "AKTUALNOŚCI" : "NEWS"} />
        </ListItem>
      </Link>
      <Link
        className={`${commonClasses.link} ${commonClasses.colorBlack}`}
        to="/partnership"
      >
        <ListItem button>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText
            primary={language === "pl" ? "WSPÓŁPRACA" : "COOPERATION"}
          />
        </ListItem>
      </Link>
      <Link
        className={`${commonClasses.link} ${commonClasses.colorBlack}`}
        to="/contact"
      >
        <ListItem button divider>
          <ListItemIcon>
            <ContactSupportIcon />
          </ListItemIcon>
          <ListItemText primary={language === "pl" ? "KONTAKT" : "CONTACT"} />
        </ListItem>
      </Link>
      <RemoteLink
        className={`${commonClasses.link} ${commonClasses.colorBlack}`}
        href="https://fitnessmlyn.gymmanager.com.pl/user/login"
      >
        <ListItem button>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText
            primary={language === "pl" ? "ZALOGUJ" : "SIGN IN / UP"}
          />
        </ListItem>
      </RemoteLink>
    </List>
  )
}
