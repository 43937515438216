import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

import Footer from "./Footer"
import Header from "./Header"

type Props = {
  children: React.ReactNode
}

const useStyles = makeStyles({
  flex: {
    flex: 1,
  },
})

export default function Layout({ children }: Props) {
  const classes = useStyles()

  return (
    <>
      <CssBaseline />
      <Header />
      {children && (
        <Container className={classes.flex} disableGutters>
          {children}
        </Container>
      )}
      <Footer />
    </>
  )
}
