import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import YouTubeIcon from "@material-ui/icons/YouTube"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import RemoteLink from "../RemoteLink"

type SOCIAL_TYPES = "FACEBOOK" | "INSTAGRAM" | "YOUTUBE"

type SocialMediaLinkProps = {
  className: string | undefined
  name: SOCIAL_TYPES
  useIcon?: boolean
}

type SocialMedia = {
  address: string
  icon: React.ReactNode
}

const useStyles = makeStyles(() => ({
  container: {
    alignContent: "center",
    alignItems: "center",
    display: "flex",
  },
}))

const SOCIAL_MEDIA = new Map<SOCIAL_TYPES, SocialMedia>()

SOCIAL_MEDIA.set("FACEBOOK", {
  address: "https://www.facebook.com/fitnessmlyn",
  icon: <FacebookIcon />,
})
SOCIAL_MEDIA.set("INSTAGRAM", {
  address: "https://www.instagram.com/fitnessmlyn",
  icon: <InstagramIcon />,
})
SOCIAL_MEDIA.set("YOUTUBE", {
  address: "https://www.youtube.com/channel/UCT4dQJU8IOoQjurIzx4q8qg",
  icon: <YouTubeIcon />,
})

export function SocialMediaLink({
  className,
  name,
  useIcon,
}: SocialMediaLinkProps) {
  const socialMedia = SOCIAL_MEDIA.get(name)

  const classes = useStyles()

  if (!socialMedia) {
    return null
  }

  return (
    <RemoteLink className={className} href={socialMedia.address}>
      {useIcon ? (
        <div className={classes.container}>{socialMedia.icon}</div>
      ) : (
        name
      )}
    </RemoteLink>
  )
}
