import { Typography } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import {
  FooterDocs,
  FooterInfo,
  FooterPartners,
  FooterSocials,
  FooterPartsProps,
  useStyles,
} from "./parts"

const FooterLayout = ({ translation }: FooterPartsProps) => {
  const {
    site: {
      siteMetadata: { siteName },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteName
        }
      }
    }
  `)

  const classes = useStyles()

  return (
    <Typography className={classes.container} component="div">
      <Container className={classes.footer} component="footer" disableGutters>
        <Grid container justifyContent="space-evenly">
          <Grid item>
            <FooterInfo translation={translation} />
          </Grid>
          <Grid item>
            <FooterDocs translation={translation} />
          </Grid>
          <Grid item>
            <FooterPartners translation={translation} />
          </Grid>
          <Grid item>
            <FooterSocials />
          </Grid>
          <Grid alignItems="center" container justifyContent="space-around">
            <Typography variant="overline">
              {`© ${new Date().getFullYear()} ${siteName}, All rights reserved.`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  )
}

export default FooterLayout
