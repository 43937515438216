import React from "react"
import Grid from "@material-ui/core/Grid"

import { SocialMediaLink } from "../../Header/utils"

import useStyles from "./useStyles"

export default function FooterSocials() {
  const classes = useStyles()

  return (
    <Grid
      alignContent="center"
      className={classes.fullHeight}
      container
      justifyContent="center"
    >
      <SocialMediaLink
        className={classes.socialMediaLink}
        name="FACEBOOK"
        useIcon
      />
      <SocialMediaLink
        className={classes.socialMediaLink}
        name="INSTAGRAM"
        useIcon
      />
      <SocialMediaLink
        className={classes.socialMediaLink}
        name="YOUTUBE"
        useIcon
      />
    </Grid>
  )
}
