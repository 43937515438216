import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import PhoneIcon from "@material-ui/icons/Phone"
import ScheduleIcon from "@material-ui/icons/Schedule"

import FooterIcon from "./FooterIcon"

import { useStyles } from "."

export type Props = {
  translation: {
    openHours: string
  }
}

export default function FooterInfo({ translation }: Props) {
  const classes = useStyles()

  return (
    <Grid
      className={classes.fullHeight}
      container
      direction="column"
      justifyContent="center"
    >
      <Typography variant="h3">{`FITNESS MŁYN`}</Typography>
      <FooterIcon Icon={ScheduleIcon} text={translation.openHours} />
      <FooterIcon Icon={PhoneIcon} text="+48 511 140 001" />
      <FooterIcon
        Icon={LocationOnIcon}
        text="ul. Dolnych Młynów 5, 31-124 Kraków"
      />
    </Grid>
  )
}
