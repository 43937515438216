import React from "react"

import regulamin from "../../media/pdfs/regulamin.pdf"

import FooterLayout from "./FooterLayout"

const FooterPl = () => {
  const translation = {
    covidPolicy: "REGULAMIN COVID-19",
    links: {
      benefit: {
        link: "https://www.benefitsystems.pl/",
        title: "Akceptujemy karty Benefit",
      },
      fitprofit: {
        link: "https://www.fitprofit.pl/",
        title: "Akceptujemy karty Fit Profit",
      },
      medicover: {
        link: "https://medicoversport.pl/",
        title: "Akceptujemy karty Medicover Sport",
      },
    },
    openHours: "PN - PT 8:00 - 21:00, SOB 9:00 - 16:00, ND 10:00 - 17:00",
    privacyPolicy: "POLITYKA PRYWATNOŚCI",
    regulations: regulamin,
    terms: "REGULAMIN KLUBU",
  }

  return <FooterLayout translation={translation} />
}

export default FooterPl
