import React from "react"
import Grid from "@material-ui/core/Grid"

import logoBenefit from "../../../media/images/logo-benefit.png"
import logoFitprofit from "../../../media/images/logo-fitprofit.png"
import logoMedicover from "../../../media/images/logo-medicover.jpg"
import RemoteLink from "../../RemoteLink"

import useStyles from "./useStyles"

type Link = {
  link: string
  title: string
}

export type Props = {
  translation: {
    links: {
      benefit: Link
      fitprofit: Link
      medicover: Link
    }
  }
}

export default function FooterPartners({ translation }: Props) {
  const classes = useStyles()

  return (
    <Grid
      className={classes.fullHeight}
      container
      direction="column"
      justifyContent="space-evenly"
    >
      <RemoteLink href={translation.links.benefit.link}>
        <img
          alt={translation.links.benefit.title}
          className={classes.partnerImg}
          src={logoBenefit}
        />
      </RemoteLink>
      <RemoteLink href={translation.links.fitprofit.link}>
        <img
          alt={translation.links.fitprofit.title}
          className={classes.partnerImg}
          src={logoFitprofit}
        />
      </RemoteLink>
      <RemoteLink href={translation.links.medicover.link}>
        <img
          alt={translation.links.medicover.title}
          className={classes.partnerImg}
          src={logoMedicover}
        />
      </RemoteLink>
    </Grid>
  )
}
