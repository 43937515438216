import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import React, { useContext } from "react"

import { Language, LanguageContext } from "../i18n"

// function changePathnameLanguage(pathname: string, newLanguage: Language) {
//   const oldLanguage: Language = pathname.startsWith("/en/") ? "en" : "pl"

//   if (newLanguage === oldLanguage) {
//     return pathname
//   }

//   const newPathname =
//     newLanguage === "pl" ? pathname.substring("/en".length) : `/en${pathname}`

//   return newPathname
// }

type Props = {
  useLongNames?: boolean
}

const useStyles = makeStyles((theme) => ({
  select: {
    "& svg": {
      color: theme.palette.common.white,
    },
    "&:after": {
      borderColor: theme.palette.common.white,
    },
    "&:before": {
      borderColor: theme.palette.common.white,
    },
    color: theme.palette.common.white,
  },
}))

export default function LanguageChanger({ useLongNames }: Props) {
  const { language, setLanguage } = useContext(LanguageContext)
  const classes = useStyles()
  // const { location, navigate } = history

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const newLanguage = event.target.value as Language

    setLanguage(newLanguage)
    navigate(newLanguage === "pl" ? "/" : `/${newLanguage}`)
    // navigate(changePathnameLanguage(location.pathname, newLanguage))
  }

  return (
    <Select className={classes.select} onChange={handleChange} value={language}>
      <MenuItem value="pl">{useLongNames ? "POLSKI" : "PL"}</MenuItem>
      <MenuItem value="en">{useLongNames ? "ENGLISH" : "EN"}</MenuItem>
    </Select>
  )
}
