import Hidden from "@material-ui/core/Hidden"
import React from "react"

type Props = {
  children: React.ReactNode
}

export default function HiddenOnDesktop({ children }: Props) {
  return <Hidden smUp>{children}</Hidden>
}
