import React from "react"

import regulations from "../../media/pdfs/regulations.pdf"

import FooterLayout from "./FooterLayout"

const FooterEn = () => {
  const translation = {
    covidPolicy: "COVID-19 GUIDELINES",
    links: {
      benefit: {
        link: "https://www.benefitsystems.pl/en/",
        title: "We accept Benefit card",
      },
      fitprofit: {
        link: "https://www.fitprofit.pl/",
        title: "We accept Fit Profit card",
      },
      medicover: {
        link: "https://medicoversport.pl/en",
        title: "We accept Medicover Sport card",
      },
    },
    openHours: "Weekday 8AM - 9PM, Saturday 9AM - 4PM, Sunday 10AM - 5PM",
    privacyPolicy: "PRIVACY POLICY",
    regulations,
    terms: "TERMS AND CONDITIONS",
  }

  return <FooterLayout translation={translation} />
}

export default FooterEn
