import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

// import desktopLogo from "../../media/images/FM-logo-pion.png"
import desktopSmallLogo from "../../media/images/FM-logo-poziom.png"
import { Link } from "../i18n"

import HeaderCommon from "./HeaderCommon"
import MenuDektop from "./MenuDektop"

// type Props = {
//   scrollTrigger: boolean
// }

const useStyles = makeStyles({
  container: { paddingTop: "7px" },
  image: {
    "@media (min-width: 700px)": {
      width: "250px",
    },
    "@media (min-width: 750px)": {
      width: "300px",
    },
    width: "175px",
  },
})

export default function HeaderDesktop() {
  // if (scrollTrigger) {
  const classes = useStyles()

  return (
    <>
      <HeaderCommon />
      <Container className={classes.container}>
        <Grid alignItems="center" container justifyContent="space-between">
          <Link to="/">
            <img
              alt="small logo"
              className={classes.image}
              src={desktopSmallLogo}
            />
          </Link>
          <MenuDektop />
        </Grid>
      </Container>
    </>
  )
  // }

  // return (
  //   <>
  //     <HeaderCommon />
  //     <Container>
  //       <Grid container direction="row" justifyContent="space-between">
  //         <Grid css={{ margin: "10px" }} item>
  //           <Link to={"/"}>
  //             <img alt="logo" css={{ width: "150px" }} src={desktopLogo} />
  //           </Link>
  //         </Grid>
  //         <Grid item>
  //           <Grid
  //             alignContent="space-between"
  //             container
  //             css={{ height: "100%" }}
  //             direction="column"
  //             justifyContent="space-between"
  //           >
  //             <Grid css={{ marginTop: "30px" }} item />
  //             <MenuDektop />
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     </Container>
  //   </>
  // )
}
