import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.black,
  },
  footer: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  footerIcon: {
    marginBottom: "10px",
    marginRight: "5px",
  },
  footerLink: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    textDecoration: "none",
  },
  fullHeight: {
    height: "100%",
  },
  linksContainer: {
    "@media (max-width: 600px)": {
      minWidth: 300,
      textAlign: "center",
    },
  },
  partnerImg: {
    display: "block",
    marginBottom: theme.spacing(1),
    width: "133px",
  },
  socialMediaLink: {
    color: theme.palette.common.white,
    marginRight: "10px",
  },
  zlol: {
    marginBottom: "0.5em",
    marginTop: "0.5em",
  },
}))

export default useStyles
