import React from "react"
import Grid from "@material-ui/core/Grid"

import rodo from "../../../media/pdfs/rodo.pdf"
import covid from "../../../media/pdfs/covid-policy.pdf"

import useStyles from "./useStyles"

export type Props = {
  translation: {
    regulations: string
    covidPolicy: string
    privacyPolicy: string
    terms: string
  }
}

export default function FooterDocs({ translation }: Props) {
  const classes = useStyles()

  return (
    <Grid
      className={classes.fullHeight}
      container
      direction="column"
      justifyContent="center"
    >
      <Grid className={classes.zlol} item>
        <a className={classes.footerLink} href={rodo}>
          {translation.privacyPolicy}
        </a>
      </Grid>
      <Grid className={classes.zlol} item>
        <a className={classes.footerLink} href={translation.regulations}>
          {translation.terms}
        </a>
      </Grid>
      <Grid className={classes.zlol} item>
        <a className={classes.footerLink} href={covid}>
          {translation.covidPolicy}
        </a>
      </Grid>
    </Grid>
  )
}
