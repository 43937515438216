import Hidden from "@material-ui/core/Hidden"
import React from "react"

type Props = {
  children: React.ReactNode
}

export default function HiddenOnMobile({ children }: Props) {
  return <Hidden xsDown>{children}</Hidden>
}
