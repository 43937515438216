import Drawer from "@material-ui/core/Drawer"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import MenuIcon from "@material-ui/icons/Menu"
import React, { useState } from "react"

import useCommonStyles from "../../library/useCommonStyles"
import desktopLogo from "../../media/images/FM-logo-pion.png"
import desktopSmallLogo from "../../media/images/FM-logo-poziom.png"
import { Link } from "../i18n"

import LanguageChanger from "./LanguageChanger"
import MenuMobile from "./MenuMobile"
import { SocialMediaLink } from "./utils"

type Props = {
  scrollTrigger: boolean
}

const useStyles = makeStyles((theme) => ({
  container: { paddingTop: "7px" },
  header: {
    color: theme.palette.primary.main,
    fontSize: "60px",
  },
  headerScrolled: {
    color: theme.palette.primary.main,
    fontSize: "40px",
  },
  linkMargin: {
    marginRight: "15px",
  },
  logo: {
    width: "100px",
  },
  logoScrolled: {
    width: "200px",
  },
  margin10: {
    margin: "10px",
  },
  stripeContainer: {
    backgroundColor: theme.palette.common.black,
    minHeight: "30px",
  },
}))

export default function HeaderMobile({ scrollTrigger }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)

  const OrangeStripe = () => (
    <Grid
      alignItems="center"
      className={classes.stripeContainer}
      container
      justifyContent="flex-end"
    >
      <Typography variant="h6">
        <SocialMediaLink
          className={`${commonClasses.link} ${classes.linkMargin} ${commonClasses.colorWhite}`}
          name="FACEBOOK"
        />
        <SocialMediaLink
          className={`${commonClasses.link} ${classes.linkMargin} ${commonClasses.colorWhite}`}
          name="INSTAGRAM"
        />
        <SocialMediaLink
          className={`${commonClasses.link} ${classes.linkMargin} ${commonClasses.colorWhite}`}
          name="YOUTUBE"
        />
        <span
          className={`${commonClasses.link} ${classes.linkMargin} ${commonClasses.colorPrimary}`}
        >{`|`}</span>
        <LanguageChanger />
      </Typography>
    </Grid>
  )

  if (scrollTrigger) {
    return (
      <>
        <OrangeStripe />
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid className={classes.margin10} item>
            <Link to="/">
              <img
                alt="logo"
                className={classes.logoScrolled}
                src={desktopSmallLogo}
              />
            </Link>
          </Grid>
          <Grid item>
            <IconButton aria-label="menu" onClick={handleOpen}>
              <MenuIcon className={classes.headerScrolled} />
            </IconButton>
            <Drawer
              anchor="right"
              onClose={handleClose}
              open={Boolean(anchorEl)}
            >
              <MenuMobile />
            </Drawer>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <OrangeStripe />
      <Grid container justifyContent="space-between">
        <Grid className={classes.margin10} item>
          <Link to="/">
            <img alt="logo" className={classes.logo} src={desktopLogo} />
          </Link>
        </Grid>
        <Grid item>
          <IconButton aria-label="menu" onClick={handleOpen}>
            <MenuIcon className={classes.header} />
          </IconButton>
          <Drawer anchor="right" onClose={handleClose} open={Boolean(anchorEl)}>
            <MenuMobile />
          </Drawer>
        </Grid>
      </Grid>
    </>
  )
}
