import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grid from "@material-ui/core/Grid"
import Grow from "@material-ui/core/Grow"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React, { useContext } from "react"

import useCommonStyles from "../../library/useCommonStyles"
import { LanguageContext, Link } from "../i18n"
import RemoteLink from "../RemoteLink"

type MenuLinkProps = {
  decorated?: boolean
  name: string
  last?: boolean
  remote?: boolean
  to: string
}

const useStyles = makeStyles({
  dropdownText: {
    cursor: "pointer",
    display: "inline",
    marginTop: "10px",
  },
  linkText: {
    display: "inline",
    marginTop: "10px",
  },
  textMargin: {
    marginRight: "15px",
  },
  textMarginLast: {
    marginRight: "0",
  },
})

function MenuLink({ decorated, name, last, remote, to }: MenuLinkProps) {
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const link = remote ? (
    <RemoteLink
      className={`${commonClasses.link} ${
        decorated ? commonClasses.colorPrimary : commonClasses.colorBlack
      }`}
      href={to}
    >
      {name}
    </RemoteLink>
  ) : (
    <Link
      className={`${commonClasses.link} ${
        decorated ? commonClasses.colorPrimary : commonClasses.colorBlack
      }`}
      to={to}
    >
      {name}
    </Link>
  )

  return (
    <Typography
      className={`${classes.linkText} ${
        last ? classes.textMarginLast : classes.textMargin
      }`}
      variant="h4"
    >
      {link}
    </Typography>
  )
}

type MenuDropdownOption = {
  href: string
  name: string
}

type MenuDropdownProps = {
  last?: boolean
  name: string
  options: MenuDropdownOption[]
}

function MenuDropdown({ last, name, options }: MenuDropdownProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Typography
        className={`${classes.dropdownText} ${
          last ? classes.textMarginLast : classes.textMargin
        }`}
        onClick={handleClick}
        variant="h4"
      >
        {name}
      </Typography>
      <Popper
        anchorEl={anchorEl}
        disablePortal
        open={Boolean(anchorEl)}
        role={undefined}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option) => (
                    <MenuItem key={option.name} onClick={handleClose}>
                      <Link
                        className={`${commonClasses.link} ${commonClasses.colorBlack}`}
                        to={option.href}
                      >
                        <Typography component="p" variant="h5">
                          {option.name}
                        </Typography>
                      </Link>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default function MenuDesktop() {
  const { language } = useContext(LanguageContext)

  const meetOptions = [
    {
      href: "/about-us",
      name: language === "pl" ? "O NAS" : "ABOUT US",
    },
    {
      href: "/team",
      name: language === "pl" ? "ZESPÓŁ" : "TEAM",
    },
    {
      href: "/classes",
      name: language === "pl" ? "ZAJĘCIA" : "CLASSES",
    },
  ]

  const infoOptions = [
    {
      href: "/news",
      name: language === "pl" ? "AKTUALNOŚCI" : "NEWS",
    },
    {
      href: "/partnership",
      name: language === "pl" ? "WSPÓŁPRACA" : "COOPERATION",
    },
    {
      href: "/contact",
      name: language === "pl" ? "KONTAKT" : "CONTACT",
    },
  ]

  return (
    <Grid item>
      <MenuLink
        name={language === "pl" ? "REZERWUJ" : "BOOK"}
        to="/timetable"
      />
      <MenuLink
        decorated
        name={language === "pl" ? "KUPUJ" : "BUY"}
        to="/membership"
      />
      <MenuDropdown
        name={language === "pl" ? "POZNAJ" : "MEET"}
        options={meetOptions}
      />
      <MenuDropdown
        name={language === "pl" ? "WYSZUKAJ" : "FIND"}
        options={infoOptions}
      />
      <MenuLink
        last
        name={language === "pl" ? "ZALOGUJ" : "SIGN IN / UP"}
        remote
        to="https://fitnessmlyn.gymmanager.com.pl/user/login"
      />
    </Grid>
  )
}
