import AppBar from "@material-ui/core/AppBar"
import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import React from "react"

import HiddenOnDesktop from "../HiddenOnDesktop"
import HiddenOnMobile from "../HiddenOnMobile"

import DesktopHeader from "./HeaderDesktop"
import MobileHeader from "./HeaderMobile"

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  toolbarDesktop: { height: "78px" },
  toolbarMobile: {
    height: "185px",
  },
}))

const Header = () => {
  const trigger = useScrollTrigger()
  const classes = useStyles()

  return (
    <Container disableGutters>
      <AppBar className={classes.appBar} elevation={0}>
        <HiddenOnDesktop>
          <MobileHeader scrollTrigger={trigger} />
        </HiddenOnDesktop>
        <HiddenOnMobile>
          <DesktopHeader />
        </HiddenOnMobile>
      </AppBar>
      <HiddenOnDesktop>
        <Toolbar className={classes.toolbarMobile} />
      </HiddenOnDesktop>
      <HiddenOnMobile>
        <Toolbar className={classes.toolbarDesktop} />
      </HiddenOnMobile>
    </Container>
  )
}

export default Header
