import { makeStyles } from "@material-ui/core/styles"

const orangeLogoWidth = 100

const useCommonStyles = makeStyles((theme) => ({
  colorBlack: {
    color: theme.palette.common.black,
  },
  colorPrimary: {
    // orange
    color: `${theme.palette.primary.main} !important`,
  },
  colorSecondary: {
    // gray
    color: theme.palette.secondary.main,
  },
  colorWhite: {
    color: theme.palette.common.white,
  },
  link: {
    textDecoration: "none",
  },
  pageContainer: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    paddingLeft: `${orangeLogoWidth}px`,
    paddingRight: `${orangeLogoWidth}px`,
  },
  relative: {
    position: "relative",
  },
  threeFours: {
    width: "75%",
  },
}))

export default useCommonStyles
