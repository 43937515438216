import React, { useContext } from "react"

import { LanguageContext } from "../i18n"
import { default as FooterEn } from "./Footer.en"
import { default as FooterPl } from "./Footer.pl"

export default function Footer() {
  const { language } = useContext(LanguageContext)

  if (language === "en") {
    return <FooterEn />
  }

  return <FooterPl />
}
