import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import PhoneIcon from "@material-ui/icons/Phone"
import React from "react"

const useStyles = makeStyles({
  footerIcon: {
    marginBottom: "10px",
    marginRight: "5px",
  },
})

export default function FooterIcon({
  Icon,
  text,
}: {
  Icon: typeof PhoneIcon
  text: string
}) {
  const classes = useStyles()

  return (
    <Grid alignContent="center" container>
      <Icon className={classes.footerIcon} fontSize="small" />
      {text}
    </Grid>
  )
}
