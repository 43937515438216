import Container from "@material-ui/core/Container"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import PhoneIcon from "@material-ui/icons/Phone"
import React, { useContext } from "react"

import { LanguageContext } from "../i18n"
import LanguageChanger from "./LanguageChanger"
import { SocialMediaLink } from "./utils"

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    height: "30px",
    width: "100%",
  },
  container: {
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    height: "30px",
    justifyContent: "space-between",
  },
  flex: {
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    height: "100%",
  },
  grow: {
    flexBasis: "60%",
    maxWidth: "60%",
  },
  marginTop: {
    margin: 0,
    marginTop: "3px",
  },
  socialMediaLink: {
    color: theme.palette.common.white,
    marginRight: "10px",
  },
  spacer: {
    marginRight: "10px",
  },
}))

function isOpen() {
  const now = new Date()
  const day = now.getDay()
  const isWeekday = day !== 0 && day !== 6
  const hour = now.getHours()

  if (isWeekday) {
    return hour >= 7 && hour < 21
  }

  return hour >= 9 && hour < 18
}

export default function HeaderCommon() {
  const { language } = useContext(LanguageContext)
  const classes = useStyles()

  const open = language === "pl" ? "TERAZ OTWARTE" : "NOW OPEN"
  const closed = language === "pl" ? "TERAZ ZAMKNIĘTE" : "NOW CLOSED"

  return (
    <div className={classes.background}>
      <Container>
        <div className={classes.container}>
          <div className={`${classes.flex} ${classes.grow}`}>
            <PhoneIcon fontSize="small" />
            <Typography
              className={classes.marginTop}
            >{`+48 511 140 001`}</Typography>
            <span className={classes.spacer} />
            <LocationOnIcon fontSize="small" />
            <Typography
              className={classes.marginTop}
            >{`UL. DOLNYCH MŁYNÓW 5, KRAKÓW`}</Typography>
            <span className={classes.spacer} />
            <Typography className={classes.marginTop} component="div">
              {isOpen() ? open : closed}
            </Typography>
          </div>
          <div className={classes.flex}>
            <SocialMediaLink
              className={classes.socialMediaLink}
              name="FACEBOOK"
              useIcon
            />
            <SocialMediaLink
              className={classes.socialMediaLink}
              name="INSTAGRAM"
              useIcon
            />
            <SocialMediaLink
              className={classes.socialMediaLink}
              name="YOUTUBE"
              useIcon
            />
          </div>
          <div>
            <LanguageChanger useLongNames />
          </div>
        </div>
      </Container>
    </div>
  )
}
